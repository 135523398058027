.react-calendar-timeline .rct-scroll{
    overflow-x: hidden !important;
 }

 .day-off{
    background: var(--gray) !important;
  }

  .border-dark{
    border-color: var(--gray) !important;
  }

  .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    padding: 0;
}