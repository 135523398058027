.outlined-textfield {
    position: relative;
    display: inline-flex;
  }
  
  .outlined-label {
    position: absolute;
    top: -8px;
    left: 8px;
    background-color: white;
    padding: 0 4px;
    font-size: 12px;
    color: gray;
  }
  
  .outlined-input {
    padding: 8px 14px;
    border: 1px solid gray;
    /* border-radius: 4px; */
    text-align: center;
    height: 1.4375em;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    min-width: fit-content;
    background-color: transparent;
  }

  input.input {
    width: 100%;
    box-sizing: border-box;
}
  