.pricetable {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin: "10px";
    font-family: Arial, Helvetica, sans-serif;
}

.priceth, .pricetd {
    border: 1px solid black;
    padding: 2px;
    font-family: Arial, Helvetica, sans-serif;
}

.pricetd {
    text-align: left;

}

.voucherth, .vouchertd {
    border: 1px solid black;
    padding: 2px;
    width: 20%;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;

}

.invisibletd {
    border: 0px solid white;
    width: 200px;
    font-family: Arial, Helvetica, sans-serif;
}

.priceh3, .priceh5 {
    font-family: Arial, Helvetica, sans-serif;

}

.priceh3 {
    text-align: left;

}