.flag-img-normal{
    height: 30px;
    width: 48px;
}

.grid-flags-right-bottom{
    position: absolute !important;
    bottom: 10px;
    right: 10px;
}


.grid-flags-right{
    display: flex !important;
    justify-content: flex-end;
}