@media print {
    .rcp {
        overflow: visible !important;
        font-size: 8px !important;
        text-align: center !important;
    }

    .rcp-title {
        overflow: unset !important; height: unset !important
    }
}

.rcp-title {
    overflow: hidden; height: 0
}