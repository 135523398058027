.owutitle {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10;
}

.owuul {
    margin-top : 20px;
    margin-bottom: 20px; 
    margin-left: 20px; 
    padding-left: 20px; 
    list-style-position: "inside"; 
}
.owuli {
    text-align : justify;
    padding-left: 20px;
    padding-right: 20px; 
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10;
    list-style-type: decimal;

}