
  .MuiTreeItem-root > .MuiTreeItem-content.Mui-selected {
    background: transparent;
  }
  
  .MuiTreeItem-root > .MuiTreeItem-content.Mui-selected:hover {
    background: transparent;
  }
  
  .MuiTreeItem-root > .MuiTreeItem-content.Mui-selected > .MuiTreeItem-label {
    background: transparent;
  }
  
  .MuiTreeItem-root > .MuiTreeItem-content.Mui-selected.Mui-focused {
    background: transparent;
  }