.messageRow {
      display: flex
}

.messageStyle {
      position: relative;
      margin-left: 20px;
      margin-bottom: 10px;
      padding: 10px;
      width: 80%;
      text-align: left;
      font: 400 .9em 'Open Sans', sans-serif;
      border-radius: 10px;
}

.messageContent {
      padding: 0;
      margin: 0;
      white-space: pre-wrap;
}

.messageTimeStamp {
      font-size: .75em;
      margin-top: 1px;
      margin-bottom: 1px;
      margin-right: 1px;
      margin-left: 1px;
      white-space: nowrap
}

.displayName {
      font-size: .8em;
      margin-left: 0px;
      margin-bottom: 2px;
      white-space: nowrap
}

.externalMessageColor {
      background-color: #e1f1f5;
      border: 1px solid #b3dbe7;
      width: fit-content
      
}

.internalMessageColor {
      background-color: #b7e4ab;
      border: 1px solid #85e5a6;
      width: fit-content
}

.logMessageColor {
      background-color: #dddfde;
      border: 1px solid #babfbd;
      width: fit-content
}