.MuiSelect-input[class*="MuiSelect-outlined"]{
    background: #ffff
  }

.MuiMenuItem-root.Mui-selected{
  background-color: var(--primary-selected) !important
}

.MuiMenuItem-root.Mui-selected:hover{
  background-color: var(--primary-selected-hover) !important
}

.MuiMenuItem-root:hover{
  background-color: var(--primary-hover) !important
}